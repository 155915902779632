/**
 * Forms
 */
fieldset {
  .m-mb(@rhythm);
}

legend {
  .m-mb(@rhythm / 4);

  .f-fw(bold);
  .t-tt(uppercase);
}

input,
select,
textarea {
  .b(1px solid @colour-primary);
}

input,
textarea {
  .p(1px 3px);

  &:invalid:focus,
  &:out-of-range {
    .b-bc(red);
  }
}

select {
  .p(1px 2px);
}
