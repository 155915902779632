/*
 * Tables
 * Default styling for all site tables
 */

table {
	.w(100%);
	.mx-vertical-rhythm();
}

caption {
	.p(1px, 3px, 0);
	.bg-co(@colour-primary);
	color: @colour-bg;
}

tr {
	thead & {
		.bg-co(@colour-primary);
	}

	tbody & {
		&:nth-child(even) {
			.bg-co(darken(@colour-bg, 5%));
		}

		&:hover {
			.bg-co(@colour-highlighter);
		}
	}

	tfoot & {
		.bg-co(lighten(@colour-primary, 37.5%));
	}
}

td, th {
	.b(1px solid @colour-secondary);
	.p(1px, 3px, 0);

	vertical-align: top;

	&:first-child {
		.b-bl(none);
	}

	&:last-child {
		.b-br(none);
	}

	tfoot & {
		.b-bbw(0);
		.p-pv(2px, 3px);
	}
}

th {
	color: @colour-primary;
	
	thead & {
		.f-fw(bold);
		color: @colour-bg;
	}

	tfoot & {
		color: @colour-text;
	}
}
