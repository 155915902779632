.mcc-c-contact {
	.m-mt(@rhythm);
	.mx-vertical-rhythm();

	&__person {
		.m-mb(@rhythm / 2);
		.m-ml(@gutter);
	}

	&__email {
		display: block;

		&::before {
			.m-ml(-@gutter);
			content: "\0020\2709\0020";
		}
	}

	&__name {
		display: block;
		.f-fw(bold);
	}

	&__tel {
		display: block;

		&::before {
			.m-ml(-17/@pixels-per-rem);
			content: "\0020\2706\0020";
		}
	}

	&__ttl {
		display: block;

	}
}
