.mcc-c-tbl-averages {
	&--batting {
		col, td, th {
			@media (max-width: @bp-data-1) {
				&:nth-child(9), // Fifties
				&:nth-child(10), // Mins
				&:nth-child(11), // Balls
				&:nth-child(12), // Fours
				&:nth-child(13) { // Sixes
					display: none;
				}
			}

			@media (max-width: @bp-data-2) {
				&:nth-child(8) { // Hundreds
					display: none;
				}
			}

			@media (max-width: @bp-data-3) {
				&:nth-child(2) { // Played
					display: none;
				}
			}

			@media (max-width: @bp-data-4) {
				&:nth-child(6), // High Score
				&:nth-child(4) { // Not Outs
					display: none;
				}
			}

			@media (max-width: @bp-data-6) {
				&:nth-child(3) { // Innings
					display: none;
				}
			}
		}
	}

	&--bowling {
		col, td, th {
			@media (max-width: @bp-data-1) {
				&:nth-child(8), // Five wicket hauls
				&:nth-child(9) { // Economy
					display: none;
				}
			}

			@media (max-width: @bp-data-2) {
				&:nth-child(10) { // Strike rate
					display: none;
				}
			}

			@media (max-width: @bp-data-3) {
				&:nth-child(3) { // Maidens
					display: none;
				}
			}

			@media (max-width: @bp-data-4) {
				&:nth-child(4), // Runs
				&:nth-child(7) { // Best bowling
					display: none;
				}
			}

			@media (max-width: @bp-data-6) {
				&:nth-child(2) { // Overs
					display: none;
				}
			}
		}
	}

	&--fielding {
		col, td, th {
			@media (max-width: @bp-data-6) {
				&:nth-child(2), // Catches
				&:nth-child(3) { // Stumpings
					display: none;
				}
			}
		}
	}
}

