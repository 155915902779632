.mcc-o-frm {
	&__ctrl {
		.fb();
		.fb-ai(center);

		&-lbl {
			.p-pr(@gutter);
		}

		&-elm {
			.fb-fx(1);
		}
	}
}