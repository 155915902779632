.mcc-c-masthead {
	.p(@rhythm / 2, 0);

	.fb();
	.fb-ai(center);

	.bg-co(@colour-primary);

	&__itm {
		.m-mh(@gutter / 2);

		&:first-child {
			.m-mr(auto);
		}
	}

	&__lnk {
		display: block;

		.f-lh(2);
		color: @colour-text-alternate;
		
		&:hover,
		&:active {
			.td();
		}

		&--nav {
			.b(0);
			.p-pv(7/8);
			.bg-co(transparent);
		}
	}
}
