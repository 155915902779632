.mcc-c-tbl-partnerships {
	@media (max-width: @bp-data-1) {
		.mcc-o-tbl__cell {
			&--ply {
				&-1 {
					&::after {
						content: "\20&"
					}
				}
			}

			&--runs {
				&::before {
					content: "Runs: "
				}
			}

			&--score {
				&::before {
					content: "FoW: "
				}
			}

			&--wkt {
				&::after {
					content: attr(data-suffix);
					vertical-align: super;
					.f-fsi(0.75);
				}
			}
		}
	}
}
