.mcc-c-fixture-nav {
	&__next {
		.w(8);
		.t-ta(left);

		&::before {
			content: "\00AB\0020";
		}
	}

	&__previous {
		.w(8);
		.t-ta(right);

		&::after {
			content: "\0020\00BB";
		}
	}

	&__season {
		.t-ta(center);

		@media (max-width: (@bp-grid-sm - 1/@pixels-per-rem)) {
			.fb-o(1);
		}
	}
}

