/*
 * Text
 * http: //www.w3.org/TR/css3-text/
 */

// Value(s): none | manual | auto
.t-h(@hyphens: manual) {
	hyphens: @hyphens;
}

// Value(s): none | [ first || [ force-end | allow-end ] || last ]
.t-hp(@hanging: none) {
	hanging-punctuation: @hanging;
}

// Value(s): auto | loose | normal | strict
.t-lb(@break: auto) {
	line-break: @break;
}

// Value(s): normal | <length>
.t-ls(@spacing: normal) {
	letter-spacing: @spacing;
}

// Value(s): normal | break-word
.t-ow(@wrap: normal) {
	overflow-wrap: @wrap;
}

// Value(s): start | end | left | right | center | justify | match-parent
.t-ta(@align: start) {
	text-align: @align;
}

// Value(s): auto | start | end | left | right | center | justify
.t-tal(@align: auto) {
	text-align-last: @align;
}

// Value(s): [ <length> | <percentage> ] && hanging? && each-line?
.t-ti(@indent: 0) {
	text-indent: @indent;
}

// Value(s): auto | none | inter-word | distribute
.t-tj(@justify: auto) {
	text-justify: @justify;
}

// Value(s): <integer> | <length>
.t-ts(@size: 8) {
	tab-size: @size;
}

// Value(s): none | capitalize | uppercase | lowercase | full-width
.t-tt(@transform: none) {
	text-transform: @transform;
}

// Value(s): normal | keep-all | break-all
.t-wb(@break: normal) {
	word-break: @break;
}

// Value(s): normal pre | nowrap | pre-wrap | pre-line
.t-whs(@space: normal) {
	white-space: @space;
}

// Value(s): [ normal | <length> | <percentage> ]
.t-wos(@spacing: normal) {
	word-spacing: @spacing;
}

// Value(s): normal | break-word
.t-ww(@wrap: normal) {
	word-wrap: @wrap;
}


/*
 * Text Decoration
 * http: //www.w3.org/TR/css-text-decor-3/
 */

// Value(s): <text-decoration-line> || <text-decoration-style> || <text-decoration-color>
.td(@decoration: none) {
	text-decoration: @decoration;
}

// Value(s): <color>
.td-tdc(@color) {
	text-decoration-color: @color;
}

// Value(s): none | [ underline || overline || line-through ]
.td-tdl(@line: none) {
	text-decoration-line: @line;
}

// Value(s): none | [ objects || spaces || ink || edges || box-decoration ]
.td-tdsk(@skip: none) {
	text-decoration-skip: @skip;
}

// Value(s): solid | double | dotted | dashed | wavy
.td-tdst(@style: solid) {
	text-decoration-style: @style;
}

// Value(s): <text-emphasis-style> || <text-emphasis-color>
.td-te(@emphasis: none) {
	text-emphasis: @emphasis;
}

// Value(s): <color>
.td-tec(@color) {
	text-emphasis: @color;
}

// Value(s): [ over | under ] && [ right | left ]
.td-tep(@position: over right) {
	text-emphasis: @position;
}

// Value(s): none | [ [ filled | open ] || [ dot | circle | double-circle | triangle | sesame ] ] | <string>
.td-tes(@style: none) {
	text-emphasis: @style;
}

// Value(s): none | [ <length>{2,3} && <color>? ]#
.td-ts(@shadow: none) {
	text-shadow: @shadow;
}

// Value(s): auto | [ under || [ left | right ] ]
.td-tup(@position: auto) {
	text-underline-position: @position;
}
