.mcc-c-tbl-results {
	&--pres {
		.mcc-o-tbl__col--score {
			.w(6.25);
		}
	}

	.mcc-o-tbl__cell {
		@media (max-width: @bp-data-1) {
			&--ha {
				display: none;
			}

			&--score {
				.t-ta(left);

				&::before {
					content: attr(data-label) ": ";
				}

				&:empty {
					display: none;
				}
			}

			&--mi {
				&:empty {
					display: none;
				}
			}
		}

		@media (min-width: (@bp-data-5 + 1/@pixels-per-rem)) and (max-width: @bp-data-2) {
			&--score {
				&:empty {
					display: block;
					color: transparent;
				}
			}
		}
	}
}
