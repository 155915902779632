/*
 * Backgrounds & Borders
 * http://www.w3.org/TR/css3-background/
 */

// Value(s): [ <bg-layer> , ]* <final-bg-layer>
// Where <bg-layer> = <bg-image> || <position> [ / <bg-size> ]? || <repeat-style> || <attachment> || <box> || <box>
// Where <final-bg-layer> = <bg-image> || <position> [ / <bg-size> ]? || <repeat-style> || <attachment> || <box> || <box> || <'background-color'>
.bg(@bg) {
	background: @bg;
}

// Value(s): <attachment> [ , <attachment> ]*
// Where <attachment> = scroll | fixed | local
.bg-a(@attachment: scroll) {
	background-attachment: @attachment;
}

// Value(s): <box> [ , <box> ]*
// Where box = border-box | padding-box | content-box
.bg-cl(@clip: border-box) {
	background-clip: @clip;
}

// Value(s): <color>
.bg-co(@color: transparent) {
	background-color: @color;
}

// Value(s): <bg-image> [ , <bg-image> ]*
// Where <bg-image> = <image> | none
.bg-i(@image: none) {
	background-image: url(@image);
}

// Value(s): <box> [ , <box> ]*
// Where box = border-box | padding-box | content-box
.bg-o(@origin: border-box) {
	background-origin: @origin;
}

// Value(s): <position> [ , <position> ]*
// Where <position> = [ [ left | center | right | top | bottom | <percentage> | <length> ] | [ left | center | right | <percentage> | <length> ] [ top | center | bottom | <percentage> | <length> ] | [ center | [ left | right ] [ <percentage> | <length> ]? ] && [ center | [ top | bottom ] [ <percentage> | <length> ]? ] ]
.bg-p(@position: 0% 0%) {
	background-position: @position;
}

// Value(s): <repeat-style> [ , <repeat-style> ]*
// Where <repeat-style> = none | repeat-x | repeat-y | [repeat | space | round | no-repeat]{1,2}
.bg-r(@repeat: repeat) {
	background-repeat: @repeat;
}

// Value(s): <bg-size> [ , <bg-size> ]*
// Where <repeat-style> = [ <length> | <percentage> | auto ]{1,2} | cover | contain
.bg-s(@size: auto) {
	background-size: @size;
}


// Value(s): [<line-width> || <line-style> || <color>]{1,4}
// Where <line-style> = none | hidden | dotted | dashed | solid | double | groove | ridge | inset | outset
// Where <line-width> = <length> | thin | medium | thick
.b(@v: 0) {
	border: @v;
}
.b(@v1, @v2) {
	.b-bv(@v1);
	.b-bh(@v2);
}
.b(@v1, @v2, @v3) {
	.b-bv(@v1, @v3);
	.b-bh(@v2);
}
.b(@v1, @v2, @v3, @v4) {
	.b-bv(@v1, @v3);
	.b-bh(@v2, @v4);
}

.b-bt(@v: 0) {
	border-top: @v;
}
.b-btc(@color) {
	border-top-color: @color;
}
.b-bts(@style: none) {
	border-top-style: @style;
}
.b-btw(@width: medium) {
	border-top-width: @width;
}

.b-br(@v: 0) {
	border-right: @v;
}
.b-brc(@color) {
	border-right-color: @color;
}
.b-brs(@style: none) {
	border-right-style: @style;
}
.b-brw(@width: medium) {
	border-right-width: @width;
}

.b-bb(@v: 0) {
	border-bottom: @v;
}
.b-bbc(@color) {
	border-bottom-color: @color;
}
.b-bbs(@style: none) {
	border-bottom-style: @style;
}
.b-bbw(@width: medium) {
	border-bottom-width: @width;
}

.b-bl(@v: 0) {
	border-left: @v;
}
.b-blc(@color) {
	border-left-color: @color;
}
.b-bls(@style: none) {
	border-left-style: @style;
}
.b-blw(@width: medium) {
	border-left-width: @width;
}

.b-bc(@color) {
	border-color: @color;
}
.b-bs(@style: none) {
	border-style: @style;
}
.b-bw(@width: medium) {
	border-width: @width;
}

.b-bv(@v: 0) {
	.b-bt(@v);
	.b-bb(@v);
}
.b-bv(@v1, @v2) {
	.b-bt(@v1);
	.b-bb(@v2);
}
.b-bh(@v: 0) {
	.b-bl(@v);
	.b-br(@v);
}
.b-bh(@v1, @v2) {
	.b-bl(@v1);
	.b-br(@v2);
}


// Value(s): [ <length> | <percentage> ]{1,4} [ / [ <length> | <percentage> ]{1,4} ]?
// Value(s): [ <length> | <percentage> ]{1,2}
.br(@v: 0) {
	.check-value(@v, border-radius);
}
.br(@v1, @v2) {
	.br-brb(@v1);
	.br-brf(@v2);
}
.br(@v1, @v2, @v3) {
	.br-brb(@v1, @v3);
	.br-brf(@v2);
}
.br(@v1, @v2, @v3, @v4) {
	.br-brb(@v1, @v3);
	.br-brf(@v2, @v4);
}

.br-brtl(@v: 0) {
	.check-value(@v, border-top-left-radius);
}
.br-brtr(@v: 0) {
	.check-value(@v, border-top-right-radius);
}
.br-brbl(@v: 0) {
	.check-value(@v, border-bottom-left-radius);
}
.br-brbr(@v: 0) {
	.check-value(@v, border-bottom-right-radius);
}

// Border radius top-left & bottom-right (back slash)
.br-brb(@v1) {
	.br-brtl(@v1);
	.br-brbr(@v1);
}
.br-brb(@v1, @v2) {
	.br-brtl(@v1);
	.br-brbr(@v2);
}
// Border radius top-right & bottom-left (forward slash)
.br-brf(@v1) {
	.br-brtr(@v1);
	.br-brbl(@v1);
}
.br-brf(@v1, @v2) {
	.br-brtr(@v1);
	.br-brbl(@v2);
}


// Value(s): <‘border-image-source’> || <‘border-image-slice’> [ / <‘border-image-width’> | / <‘border-image-width’>? / <‘border-image-outset’> ]? || <‘border-image-repeat’>
.bi(@bi) {
	border-image: @bi;
}

// Value(s): none | <image>
.bi-biso(@source: none) {
	border-image-source: url(@source);
}

// Value(s): [<number> | <percentage>]{1,4} && fill?
.bi-bisl(@slice: 100%) {
	border-image-slice: @slice;
}

// Value(s): [ <length> | <percentage> | <number> | auto ]{1,4}
.bi-biw(@width: 1) {
	border-image-width: width;
}

// Value(s): [ <length> | <number> ]{1,4}
.bi-bio(@outset: 0) {
	border-image-outset: @outset;
}

// Value(s): [ stretch | repeat | round | space ]{1,2}
.bi-bir(@repeat: stretch) {
	border-image-repeat: @repeat;
}


// Value(s): none | <shadow> [ , <shadow> ]*
// Where <shadow> = inset? && <length>{2,4} && <color>?
.bs(@bs) {
	box-shadow: @bs;
}
