.u-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	opacity: 0.5;
	z-index: 1;

	&[data-count="0"] {
		display: none;
	}
}
