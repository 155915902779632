/*
 * Definition List
 * Basic styles for obvious looking definition lists
 */

.mcc-o-lst-definition {
	@base-class: ~".mcc-o-lst-definition";

	&__term {
		float: left;
		clear: both;

		.w(3);

		&::after {
			content: ":\00A0";
		}

		&--right {
			.t-ta(right);
		}
	}

	&__def {
		.p-pl(3);
	}

	&--honour-board {
		@{base-class}__term {
			.w(7.5);
		}

		@{base-class}__def {
			.p-pl(7.5);
		}
	}

	&--officials {
		@{base-class}__term {
			.w(10);
		}

		@{base-class}__def {
			.p-pl(10);
		}
	}
}
