/*
 * Blocks
 * Default styles for block level elements
 */

figure,
main,
p,
section {
	.mx-vertical-rhythm();
}

p {
	.m-mt(0);
}
