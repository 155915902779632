.mcc-c-tbl-documents {
	&__col {
		&--doc-date {
			.w(8);
		}

		&--pdf {
			.w(3);
		}		
	}

	@media (max-width: @bp-data-7) {
		&, caption, tfoot, tbody, th, td {
			display: block;
		}

		thead {
			.mcc-u-sr-only();
		}

		tr {
			.fb();
			.fb-w(wrap);

			.b(1px solid @colour-secondary);
			.b-bt(none);
		}

		td {
			.b(none);
		}

		&__cell {
			&--doc-ttl {
				.fb-o(-1);
				.w(100%);
			}

			&--doc-date {
				.m-mr(auto);
			}
		}
	}

}
