.o-flex {
	display: flex;
}

	.o-flex--wrap {
		flex-wrap: wrap;
	}

	.o-flex--space-between {
		justify-content: space-between;
	}

	.o-flex__item {}