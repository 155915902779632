.mcc-o-columns {
	.c-cg(@gutter);

	&__itm {
		.c-cbi(avoid);
	}
}

@media (min-width: @bp-grid-xxs) {
	.mx-make-columns(xxs);
}

@media (min-width: @bp-grid-xs) {
	.mx-make-columns(xs);
}

@media (min-width: @bp-grid-sm) {
	.mx-make-columns(sm);
}

@media (min-width: @bp-grid-md) {
	.mx-make-columns(md);
}

@media (min-width: @bp-grid-lg) {
	.mx-make-columns(lg);
}
