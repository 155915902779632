.mcc-c-member {
	&__sub-ttl {
		.f-fw(bold);
	}

	&__elm {
		display: block;
		.t-whs(pre);
		.t-ww(break-word);

		&:empty {
			display: none;
		}

		&--telephone {
			&::before {
				content: attr(data-prefix) ":";
				display: inline-block;
				.w(1.5);
				.f-fw();
			}
		}
	}

	&-lst {
		&__itm {
			display: flex;
		}
	}
}
