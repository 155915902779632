.mcc-o-grid {
	.fb();
	.m-mh(-@gutter / 2);

	@media (min-width: @bp-grid-sm) {
		.m-mh(-@gutter);
	}

	&--no-margin {
		.m-mh(0);
	}

	&--wrap {
		.fb-w(wrap);
	}

	&--reverse {
		.fb-d(row-reverse);

		&\@sm {
			@media (min-width: @bp-grid-sm) {
				.fb-d(row-reverse);
			}
		}
	}

	&--column {
		.fb-d(column);
	}

	&--column-reverse {
		.fb-d(column-reverse);
	}

	&--around {
		.fb-jc(space-around);
	}

	&--between {
		.fb-jc(space-between);
	}

	&--center {
		.fb-jc(center);
	}

	&--end {
		.fb-jc(flex-end);
	}

	&--start {
		.fb-jc(flex-start);
	}

	&__col {
		.fb-fx(0 0 100%);
		.w-maw(100%); //IE11- bug fix
		.p-ph(@gutter / 2);
		.p-pb(@rhythm);

		@media (min-width: @bp-grid-sm) {
			.p-ph(@gutter);
		}

		&--no-spacing {
			.p(0);
		}

		&--no-v-spacing {
			.p-pb(0);
		}

		&--end {
			.m-ml(auto);
		}
	}
}

@media (min-width: @bp-grid-xxs) {
	.mx-make-grid(xxs);
}

@media (min-width: @bp-grid-xs) {
	.mx-make-grid(xs);
}

@media (min-width: @bp-grid-sm) {
	.mx-make-grid(sm);
}

@media (min-width: @bp-grid-md) {
	.mx-make-grid(md);
}

@media (min-width: @bp-grid-lg) {
	.mx-make-grid(lg);
}
