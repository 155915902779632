/*
 * Naked List
 * Cleared of all formatting
 */

.mcc-o-lst-naked {
	list-style: none;
	.m(0);
	.p(0);
}
