.mcc-c-results-summary {
	&__type {
		float: left;
		.f-fw(bold);

		&::after {
			content: ":\00A0";
		}
	}
}
