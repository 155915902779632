/*
 * Lists
 */

// Default margin and padding
ol,
ul {
	.m-mv(0, @rhythm);
	.p-pl(@gutter * 2);

	section & {
		.mx-vertical-rhythm();
	}

	// no bottom margin when nested
	li & {
		.m-mb(0);
	}
}

dl {
	.m-mv(0, @rhythm);

	section & {
		.mx-vertical-rhythm();
	}
}

// When a list follows a paragraph, collapse the spacing between the two
dl,
ol,
ul {
	p + & {
		.m-mt(-@rhythm);
	}
}

// Default list styling
ol {
	list-style-type: decimal;
}

ul {
	list-style-type: circle;
}

// Visually style the definition term
dt {
	.f-fw(bold);
}

// Remove definition spacing
dd {
	.m-ml(0);
}
