/*
 * Tables
 */
.mcc-o-tbl {
	&__ttl {
		.f-fw(bold);
	}

	&__ftr {
		&--mi {
			td {
				.t-ta(right);
			}
		}
	}

	&__col {
		&--data {
			.w(3.5);
		}

		&--date {
			.w(7.5);

			&-short {
				.w(5);
			}
		}

		&--fs {
			.w(5.25);
		}

		&--ha {
			.w(3);
		}

		// &--ho {
		// 	.w(6.5);
		// }

		// &--method {
		// 	.w(10.5);
		// }

		&--mi {
			.w(1.75);
		}

		&--score {
			.w(4);
		}
	}

	&__cell {
		&--ha {
			.t-ta(center);
		}
	}

	&--list {
		@media (max-width: @bp-data-1) {
			&, caption, thead, tfoot, tbody, tr, th, td {
				display: block;
			}

			thead {
				.mcc-u-sr-only();
			}

			tr {
				position: relative;
				.clearfix();
				.w(100%);
				.p(@rhythm / 4, @gutter / 4);
			}

			td, th {
				.b(none);
				.p-pv(1px);
			}

			.mcc-o-tbl__cell {
				&--no {
					.mcc-u-sr-only();
				}

				&--mi {
					position: absolute;
					.check-value(@rhythm / 4, top);
					.check-value(@gutter / 4, right);
				}

				&--date {
					&::before {
						content: "on ";
					}
				}

				&--loc {
					&::before {
						content: "at ";
					}
				}

				&--oppo {
					&::before {
						content: "vs ";
					}
				}
			}
		}

		@media (min-width: (@bp-data-5 + 1/@pixels-per-rem)) and (max-width: @bp-data-1) {
			.mcc-o-tbl__bdy {
				.fb();
				.fb-w(wrap);

				&-row {
					.w(50%);

					&:nth-child(even) {
						.bg-co(@colour-bg);
					}

					&:nth-child(4n),
					&:nth-child(4n-1) {
						.bg-co(darken(@colour-bg, 5%));
					}

					&:hover {
						.bg-co(@colour-highlighter);
					}
				}
			}
		}
	}

	&--primary {
		@media (max-width: @bp-data-1) {
			.mcc-o-tbl__cell {
				&--date,
				&--loc,
				&--oppo,
				&--ply {
					.w(~"calc(100% - 3.25rem)");
					.m-ml(3.25);

					.mcc-c-tbl-partnerships& {
						.w(~"calc(100% - 5.625rem)");
						.m-ml(5.625);
					}
				}

				&--primary {
					float: left;
					clear: left;
					.w(3);

					.mcc-c-tbl-partnerships& {
						.w(5.375);
					}
				}
			}
		}
	}

	&--summary {
		@media (max-width: @bp-data-1) {
			tr {
				.fb();
				.fb-w(wrap);
			}

			.mcc-o-tbl__cell {
				.b(none);
				.f-fw();
				.t-ta(left);

				&:nth-child(n+2):nth-last-child(n+2) {
					.w(percentage(1/3));

					&::before {
						content: attr(data-label) ": ";
					}
				}

				&:first-child {
					.w(100%);
					.f-fw(bold);
				}

				&--total {
					display: none;
				}

				&:empty {
					display: none;
				}
			}

			.mcc-o-tbl__ftr-row {
				.mcc-o-tbl__cell {
					&:nth-child(n+2):nth-last-child(n+2) {
						.w(20%);
					}
				}
			}
		}

		@media (max-width: @bp-data-2) {
			.mcc-o-tbl__cell {
				&:nth-child(n+2):nth-last-child(n+2) {
					.w(50%);
				}
			}

			.mcc-o-tbl__ftr-row {
				.mcc-o-tbl__cell {
					&:nth-child(n+2):nth-last-child(n+2) {
						.w(25%);
					}
				}
			}
		}

		@media (max-width: @bp-data-3) {
			.mcc-o-tbl__ftr-row {
				.mcc-o-tbl__cell {
					&:nth-child(n+2):nth-last-child(n+2) {
						.w(percentage(1/3));
					}
				}
			}
		}

		@media (max-width: @bp-data-5) {
			.mcc-o-tbl__ftr-row {
				.mcc-o-tbl__cell {
					&:nth-child(n+2):nth-last-child(n+2) {
						.w(50%);
					}
				}
			}
		}
	}
}
