/*
 * Flexbox
 * http://www.w3.org/TR/css3-flexbox/
 */

// Initialise flexbox via the 'display' property
// Value(s): flex | inline-flex
.fb(@display: flex) {
	display: @display;
}

// Set the major axis for the content to flex on
// Value(s): row | row-reverse | column | column-reverse
.fb-d(@direction: row) {
	flex-direction: @direction;
}

// Should the flexed content be single-line of multi-line?
// Value(s): row | row-reverse | column | column-reverse
.fb-w(@wrap: nowrap) {
	flex-wrap: @wrap;
}

// Shorthand for flex-direction and flex-wrap
// Value(s): <flex-direction> || <flex-wrap>
.fb-fl(@direction: row, @wrap: nowrap) {
	flex-flow: @direction @wrap;
}

// Alter the position of child element of the flexed container
// Value(s): <integer>
.fb-o(@order: 0) {
	order: @order;
}

// Define how a child element of the flexed container should expand to fill the available space.
// Value(s): none | auto | [ <flex-grow> <flex-shrink>? || <flex-basis> ]
.fb-fx(@flex: none) {
	flex: @flex;
}

// How should the child elements be arranged along the major axis?
// Value(s): flex-start | flex-end | center | space-between | space-around
.fb-jc(@align: flex-start) {
	justify-content: @align;
}

// In a multi-line flexed container, how should the lines be aligned on the minor axis(vertical if flex-direction is 'row', horizontal if 'column')?
// Value(s): flex-start | flex-end | center | space-between | space-around | stretch
.fb-ac(@align: stretch) {
	align-content: @align;
}

// Align items on the minor axis
// Value(s): flex-start | flex-end | center | baseline | stretch
.fb-ai(@align: stretch) {
	align-items: @align;
}

// Align a single item on the minor axis
// Value(s): flex-start | flex-end | center | baseline | stretch
.fb-as(@align: auto) {
	align-self: @align;
}