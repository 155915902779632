.mcc-c-unachieved {
	.m-ml(0);

	&__entry {
		span {
			.f-fsty(italic);
		}

		&:nth-last-child(n+2)::after {
			display: inline-block;
			.m-mr(3px);
			content: ",";
		}

		&:nth-last-child(2)::after {
			.m-ml(3px);
			content: " & ";
		}
	}
}
