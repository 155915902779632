.mcc-c-tbl-contributions {
	@base-class: ~".mcc-c-tbl-contributions";

	&--batting {
		col, td, th {
			@{base-class}--match& {
				@media (max-width: @bp-data-1) {
					&:nth-child(9) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-2) {
					&:nth-child(5), 	// Min
					&:nth-child(6) { 	// Balls
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(7), 	// 4s
					&:nth-child(8) { 	// 6s
						display: none;
					}
				}
			}

			@{base-class}--position& {
				@media (max-width: @bp-data-1) {
					&:nth-child(5),		// Min
					&:nth-child(6),		// Balls
					&:nth-child(9) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-2) {
					&:nth-child(7),		// 4s
					&:nth-child(8) {	// 6s
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(3) {	// How Out
						display: none;
					}
				}

				@media (max-width: @bp-data-7) {
					&:nth-child(2) {	// Opponent
						display: none;
					}
				}
			}

			@{base-class}--homeaway&,
			@{base-class}--innings&,
			@{base-class}--result&,
			@{base-class}--season& {
				@media (max-width: @bp-data-1) {
					&:nth-child(6),		// Min
					&:nth-child(7),		// Balls
					&:nth-child(10) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-2) {
					&:nth-child(8),		// 4s
					&:nth-child(9) {	// 6s
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(3) {	// Position
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(4) {	// How Out
						display: none;
					}
				}

				@media (max-width: @bp-data-7) {
					&:nth-child(2) {	// Opponent
						display: none;
					}
				}
			}

			@{base-class}--opponent& {
				@media (max-width: @bp-data-1) {
					&:nth-child(2) {	// Position
						display: none;
					}
				}

				@media (max-width: @bp-data-2) {
					&:nth-child(9) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-3) {
					&:nth-child(5) {	// Min
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(6) {	// Balls
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(7),		// 4s
					&:nth-child(8) {	// 6s
						display: none;
					}
				}
			}
		}
	}

	&--bowling {
		col, td, th {
			@{base-class}--match& {
				@media (max-width: @bp-data-2) {
					&:nth-child(8) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-3) {
					&:nth-child(7) {	// Econ
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(3) {	// Maidens
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(6) {	// Ave
						display: none;
					}
				}

				@media (max-width: @bp-data-7) {
					&:nth-child(4) {	// Runs
						display: none;
					}
				}
			}

			@{base-class}--homeaway&,
			@{base-class}--innings&,
			@{base-class}--result&,
			@{base-class}--season& {
				@media (max-width: @bp-data-1) {
					&:nth-child(8) {	// Econ
						display: none;
					}
				}

				@media (max-width: @bp-data-2) {
					&:nth-child(4),		// Maidens
					&:nth-child(9) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-3) {
					&:nth-child(7) {	// Ave
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(5) {	// Runs
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(3) {	// Overs
						display: none;
					}
				}

				@media (max-width: @bp-data-7) {
					&:nth-child(2) {	// Opponent
						display: none;
					}
				}
			}

			@{base-class}--opponent& {
				@media (max-width: @bp-data-2) {
					&:nth-child(8) {	// SR
						display: none;
					}
				}

				@media (max-width: @bp-data-3) {
					&:nth-child(3),		// Maidens
					&:nth-child(7) {	// Econ
						display: none;
					}
				}

				@media (max-width: @bp-data-4) {
					&:nth-child(4) {	// Runs
						display: none;
					}
				}

				@media (max-width: @bp-data-6) {
					&:nth-child(2) {	// Overs
						display: none;
					}
				}
			}
		}
	}

	&--fielding {
		col, td, th {
			@{base-class}--homeaway&,
			@{base-class}--result&,
			@{base-class}--season& {
				@media (max-width: @bp-data-7) {
					&:nth-child(2) {	// Opponent
						display: none;
					}
				}
			}
		}
	}
}
