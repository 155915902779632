/*
 * Fonts
 * http://www.w3.org/TR/css3-fonts/
 * NB: Do not use these shortcuts in @font-face declarations
 */

// Value(s): [ [ <font-style> || <font-variant> || <font-weight> || <font-stretch ]? <font-size> [ / <line-height> ]? <font-family> ] | caption | icon | menu | message-box | small-caption | status-bar
.f(@font) {
	font: @font;
}

// Value(s): [ <family-name> | <generic-family> ] #
.f-ff(@family) {
	font-family: @family;
}

// Value(s): <absolute-size> | <relative-size> | <length> | <percentage>
.f-fsi(@size) {
	.check-value(@size, font-size);
}

// Value(s): none | <number>
.f-fsia(@adjust: none) {
	font-size: @adjust;
}

// Value(s): normal | ultra-condensed | extra-condensed | condensed | semi-condensed | semi-expanded | expanded | extra-expanded | ultra-expanded
.f-fstr(@stretch: normal) {
	font-stretch: @stretch;
}

// Value(s): normal | italic | oblique
.f-fsty(@style: normal) {
	font-style: @style;
}

// Value(s): none | [ weight || style ]
.f-fsy(@synthesis: weight style) {
	font-synthesis: @synthesis;
}

// Value(s): normal | small-caps
.f-fv(@variant: normal) {
	font-variant: @variant;
}

// Value(s): normal | bold | bolder | lighter | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
.f-fw(@weight: normal) {
	font-weight: @weight;
}

// Value(s): normal | <number> | <length> | <percentage>
.f-lh(@height: normal) {
	line-height: @height;
}

.f-reset() {
	.f-fstr();
	.f-fsty();
	.f-fv();
	.f-fw();
}
