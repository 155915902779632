.mcc-c-all-rounders {
	&__ttl {
		.f-fw(bold);
	}

	&__entry {
		&--last {
			.m-mb(@rhythm / 2);
		}
	}
}
