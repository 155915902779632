.mcc-o-icon {
	&--more {
		display: inline-block;
		.h(1.25);
		.w(1.25);
		.b(2px outset @colour-primary);
		.br(50%);

		.f-ff(~"Times, sans-serif");
		.f-fsi(19/@pixels-per-rem);
		.f-lh(0.75);
		.f-fw(bold);
		.t-ta(center);

		.bg-co(@colour-primary);
		color:@colour-bg;

		&:active {
			.b-bs(inset);
		}
	}
}
