/*
 * Clearfix
 * Used to force an element to expand in height to completely encompass any floated children 
 * This version is for IE8 and upwards
 * See http://css-tricks.com/snippets/css/clear-fix/
 */

.clearfix() {
	&:after {
		display: table;
		clear: both;
		content: "";
	}
}

.mcc-u-clearfix {
	.clearfix();
}
