.mcc-c-ave-sum {
	&__itm {
		.fb();
		.fb-d(column);

		.w(100%);
		.b(1px solid #700);
		.p(@rhythm / 2, @gutter / 2);
	
		@media (max-width: @bp-summary-1), (min-width: @bp-summary-2) {
			position: relative;
			display: block;
		}

		&-container {
			.fb();
		}
	}

	&__ttl {
		.f-fw(bold);
	}

	&__fig {
		.fb-o(-1);

		.f-lh(1);

		@media (max-width: @bp-summary-1), (min-width: @bp-summary-2) {
			position: absolute;
			.check-value(@rhythm / 2, top);
			.check-value(@gutter / 2, right);
		}
	}

	&__bf,
	&__ha,
	&__oppo,
	&__pos,
	&__result,
	&__when,
	&__who {
		.f-fw(bold);
	}
}
