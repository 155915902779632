.mcc-c-documents-calendar {
	.c-cw(9);
	.c-cg(1);

	&__lnk {
		display: block;
		.m-mh(@gutter);

		&:hover,
		&:active {
			&::before,
			&::after {
				display: inline-block;
				.w(@gutter);
			}

			&::before {
				content: "\00BB";
				.m-ml(-@gutter);
				.t-ta(left);
			}

			&::after {
				content: "\00AB";
				.m-mr(-@gutter);
				.t-ta(right);
			}
		}
	}
}
