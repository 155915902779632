.mcc-c-rcd {
	.fb();

	&__value {
		.w(9.5);
		.f-lh(1);
		.t-ta(right);
	}

	&__instances {
		// .fb-fx(1);
	}

	&__instance {
		position:relative;
		.fb();
		.fb-d(column);
		.w(100%);
		// .p-pl(@gutter);
	}

	&__mi {
		position:absolute;
		.check-value(0, top);
		.check-value(0, right);
	}
}
