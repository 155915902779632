.mcc-c-branding {
	&__img {
		.w(auto);
		.h(2);
	}

	&__txt {
		.f-ff("Cloister Black");
		.f-lh(1);
		color: @colour-text-alternate;

		&--sm {
			@media (min-width: @bp-branding-md) {
				display: none;
			}
		}

		&--md {
			display: none;

			@media (min-width: @bp-branding-md) and (max-width: (@bp-branding-lg - 1/@pixels-per-rem)) {
				display: inline;
			}
		}

		&--lg {
			display: none;

			@media (min-width: @bp-branding-lg) {
				display: inline;
			}	
		}
	}
}
