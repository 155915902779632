.mcc-c-breadcrumb {
	.m-mb(@rhythm - 1);

	.fb();

	&__label {
		.fb-fx(none);
	}

	&__list {
		.fb();
		.fb-fl(row, wrap);

		list-style-type:none;
	}

	&__listitem {
		.p-pl(0.2);

		&:nth-last-child(n+2) {
			&::after {
				content: "/";
			}
		}

		&:last-child {
			.fb-fx(1);
		}
	}

	&__link {
		.p-pr(0.2);

		color: @colour-primary;
		
		&:hover,
		&:active {
			.td(underline);
		}
	}
}
