.mcc-o-blk {
	.w(100%);
	.mx-vertical-rhythm();

	&--eh {
		.fb();
		.fb-d(column);
	}

	&__hdr {
		.m-mb(@rhythm / 2);
		.b-bb(1px solid @colour-primary);
	}

	&__ttl {
		.f-fw(bold);
	}

	&__bdy {
		.m-mb(@rhythm / 2);
	}

	&__ftr {
		.m-mt(auto);
		.b-bt(1px dotted @colour-primary);
		.b-bb(3px double @colour-primary);
		// .p-pt(0.3);

		.t-ta(right);
	}
}
