.u-spinner {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 1;
	z-index: 2;

	&[data-count="0"] {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		height: 40px;
		width: 40px;
		border: solid 3px @colour-secondary;
		border-bottom-color: @colour-primary;
		border-radius: 50%;
			animation: 2s linear infinite spinner;
		transform: translate3d(-50%, -50%, 0);
		transform-origin: center;
		will-change: transform;
	}
}

@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
