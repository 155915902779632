.mcc-c-frm-view-options {
	&__ctrl {
		@media (min-width: @bp-grid-md) {
			.fb-d(column);
		}

		&-lbl {
			@media (min-width: @bp-grid-md) {
				.w(100%);
				.p(0);
			}
		}

		&-elm {
			@media (min-width: @bp-grid-md) {
				.w(100%);
				.fb-fx();
			}
		}
	}
}
