.mcc-c-tbl-high-scores {
	@media (max-width: @bp-data-1) {
		.mcc-o-tbl__cell {
			&--details {
				display: inline-block;

				&:empty {
					display: none;

					~ .mcc-o-tbl__cell--details:not(:empty) {
						.w(3);
						.t-ta(right);

						+ .mcc-o-tbl__cell--details {
							.w(auto);
						}
					}
				}
			}

			&--mins {
				.w(3);
				.t-ta(right);

				&::after {
					content: "m";
				}
			}

			&--balls {
				&::after {
					content: "b";
				}
			}

			&--fours {
				&::after {
					content: "x4";
				}
			}

			&--sixes {
				&::after {
					content: "x6";
				}
			}

			&--sr {
				&::before {
					content: "SR: ";
				}
			}
		}
	}
}
