.mcc-o-frm-radio-grp {
	.fb();
	.fb-w(wrap);

	&__itm {
		.m-ml(@gutter);

		&:first-child {
			.m-ml(0);
		}
	}

	&__lbl {
		.m-ml(@gutter / 4);
	}
}
