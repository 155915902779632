/*
 * Screen Reader only
 * Hide an element offscreen without affecting the ability of a screen reader to pick it up
 * The horizontal direction to move it depends on text direction
 * See https://css-tricks.com/snippets/css/accessibilityseo-friendly-css-hiding/
 * Requires: "check-value" mixin
 */

.sr-only() {
	position: absolute;
	.check-value(-9999, top);
	.check-value(-9999, left);

	// html[dir="ltr"] & {
	// }

	// html[dir="rtl"] & {
	// 	.check-value(-9999, right);
	// }
}

.mcc-u-sr-only {
	.sr-only();
}
