/*
 * Layout
 * Site container styling
 */

.mcc-o-lyt {
	.fb();
	.fb-d(column);

	.w(100%);
	overflow-x: hidden;

	@media (min-width: @bp-background) {
		.w(~"calc(100vw - (@{gutter} * 4))");
		.w-maw(100);
		.m-mh(auto);

		opacity: 0.925;
	}

	@media (min-width: @bp-menu-max) {
		.no-js & {
			.fb();
			.fb-d(column);
		}
	}

	&__hdr {
		.bg-co(@colour-bg);

		@media (min-width: @bp-menu-max) {
			.no-js & {
				.fb-o(-2);
			}
		}
	}

	&__ttl {
	}

	&__mnu {
		@media (min-width: @bp-menu-max) {
			.no-js & {
				.fb-o(-1);
			}
		}
	}

	&__ftr {
		.b-bt(1px solid @colour-primary);
		.p(@rhythm /  2, @gutter / 2);
		.bg-co(@colour-bg);

		@media (min-width: @bp-background) {
			.br(@gutter / 2, @gutter / 2, 0, 0);
			.b-bt(none);
		}
	}
}
