/*
 * Footer Nav
 */

.mcc-c-footer-mnu {
	&__ttl {
		.f-fw(bold);
	}

	&__lst {}

	&__itm {}

	&__lnk {}
}
