.mcc-o-media {
	.fb();
	.fb-ai(flex-start);

	&__fig {
		.m-mr(@gutter);
	}

	&__bdy {
		.fb-fx(1);
	}
}
