/*
 * Page
 * Page container styling
 */

.mcc-o-pg {
	.fb-fx(1);

	position: relative;
	.p(@rhythm, @gutter / 2);
	.bg-co(@colour-bg);

	@media (min-width: @bp-grid-sm) {
		.p-ph(@gutter);
	}

	@media (min-width: @bp-background) {
		.m-mv(@rhythm);
		.br(@gutter);
	}

	&__hdr {
		.m-mb(@rhythm);

		.fb();
		.fb-d(column-reverse);
	}

	&__ttl {
		.f-fw(bold);

		color: @colour-primary;
	}

	&__bdy {}

	&__ftr {
		.m-mb(@rhythm);
		.t-ta(right);
	}
}
