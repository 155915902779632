@import "backgrounds-borders";
@import "columns";
@import "flexbox";
@import "fonts";
@import "margin";
@import "padding";
@import "text";

/* 
 * Default font size
 * Set the value of 1rem to 10px (62.5% of 16 is 10). This allows for the Base 10 arithmetic.
 * NB: Chrome still uses 1rem = 16px in media queries. :(
 */
@default-font-size:16;
@default-unit: rem;
// html {
// 	.f-fsi(62.5%);
// }

// Mixin adapted from
// http://stackoverflow.com/questions/17783454/lesscss-when-a-number-has-no-units/17785852#17785852
.check-value(@v, @p:margin) {
	.run-checks() when not (isnumber(@v)) {
		@output: @v;
	}
	.run-checks() when (isnumber(@v)) and (@v = 0) {
		@output: @v;
	}
	.run-checks() when (isnumber(@v)) and not (@v = 0) {
		@temp-output: (@v * unit(1, @default-unit));
		@is-rem: isunit(@v, 'rem'); //a bug with rem required this extra step
		.check-rem() when not (isunit(@temp-output, 'rem')) and not (@is-rem) {
			// keeps passed in non-rem unit or sets to default when non rem
			@output: (@v * unit(1, @default-unit)); 
		}
		.check-rem() when (isunit(@temp-output, 'rem')), (@is-rem) {
			// keeps passed in rem unit and value 
			// and sets a px value also (for IE8-)
			@output: unit(@v, rem);
		}
		.check-rem();
	}

	.run-checks();
	@{p}: @output;
}


/*
 * Clearfix
 * IE8 and upwards version
 * http://css-tricks.com/snippets/css/clear-fix/
 */
// .clearfix() {
// 	clear:both;
// 	content:"";
// 	display:table;
// }

/*
 * Screen Reader only
 * Hide text without affecting accessibility
 */
// .sr-only() {
// 	position:absolute;
// 	top:-9999px;
// 	:root[dir="ltr"] & {
// 		left:-9999px;
// 	}
// 	:root[dir="rtl"] & {
// 		right:-9999px;
// 	}
// }

// Values: <length> | <percentage> | auto | inherit
.h(@height) {
	.check-value(@height, height);
}
// Value(s): <length> | <percentage> | none | inherit
.h-mah(@height) {
	.check-value(@height, max-height);
}
// Value(s): <length> | <percentage> | inherit
.h-mih(@height) {
	.check-value(@height, min-height);
}

// Values: <length> | <percentage> | auto | inherit
.w(@width) {
	.check-value(@width, width);
}
// Value(s): <length> | <percentage> | none | inherit
.w-maw(@width) {
	.check-value(@width, max-width);
}
// Value(s): <length> | <percentage> | inherit
.w-miw(@width) {
	.check-value(@width, min-width);
}
