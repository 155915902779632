/*
 * Links
 * Some common link type styling
 */

.mcc-o-lnk {
	&--arrow {
		.f-fsty(italic);

		&:after {
			content: "\0020\00BB";
		}
	}

	&--external {
		&:visited {
			color: @colour-secondary;
		}
	}
}
