.mcc-c-tablist {
	.b-bt(1px solid @colour-primary);
	.b-bl(1px solid @colour-primary);

	&__itm {
		.fb();
	}

	&__lnk {
		.fb-fx(1);

		display: block;
		.b-br(1px solid @colour-primary);
		.b-bb(1px solid @colour-primary);
		.p(@rhythm / 4, @gutter / 2);

		&:hover,
		&:active {
			.bg-co(@colour-secondary);
			.td();
		}

		&.is-active {
			.bg-co(@colour-primary);
			color: @colour-text-alternate;
		}
	}
}
