.mcc-s-news {
	@className: ~".mcc-s-news";

	.mx-vertical-rhythm(@rhythm, @rhythm);

	.mcc-o-blk & {
		.m-mb(0);
	}

	&__hdr {
		.m-mb(@rhythm / 2);
		.b-bb(1px solid @colour-primary);

		@{className}--birth & {
			.b-bbc(@colour-birth);
		}

		@{className}--marriage & {
			.b-bbc(@colour-marriage);
		}

		@{className}--death & {
			.b-bbc(@colour-death);
		}
	
		@{className}--within & {
			.b-bbw(0);
		}
	}


	&__bdy {
		.m-mb(@rhythm / 2);
	}

	&__ftr {
		.f-fsty(italic);
		.t-ta(right);
	}

	h1 {
		.f-fw(bold);
	}

	&--birth {
		color: @colour-birth;
	}

	&--marriage {
		color: @colour-marriage;
	}

	&--death {
		color: @colour-death;
	}

	&--teamsheet {
		// ol {
		// 	.m-mt(@rhythm);
		// }
	}
}
