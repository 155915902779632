/*
 * Fonts
 */

@font-face {
	font-family: "Cloister Black";
	src: url("../fonts/CloisterBlack.woff");
	src: url("../fonts/CloisterBlack.eot?#iefix") format("embedded-opentype"),
		url("../fonts/CloisterBlack.woff") format("woff"),
		url("../fonts/CloisterBlack.ttf") format("truetype");
	.f-fsty(normal);
	.f-fw(normal);
}

@default-font-size: 1rem;
@default-line-height: 1.5;
