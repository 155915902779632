/*
 * The following file is a mixture of two stylesheets (plus some additions of my own):
 * 1) Y! reset v3.18.1
 * 2) Normalize.css v3.0.2
 */


/*
 * Don't use the content box
 * http://css-tricks.com/international-box-sizing-awareness-day/
 */
*,
*::before,
*::after {
	box-sizing: border-box;
}


/*
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling user zoom.
 */
html {
	font-family: sans-serif; /* 1 */
	-ms-text-size-adjust: 100%; /* 2 */
	-webkit-text-size-adjust: 100%; /* 2 */
}


/* HTML5 display definitions
	 ========================================================================== */

/*
 * Remove default margin.
 */
body {
	.m(0);
}


/* Text-level semantics
	 ========================================================================== */

/*
 * Clear default styling when not needed.
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr {
	.b(none);
	.f-fv();
}
abbr[title] {
	.b-bb(1px dotted @colour-primary);
	.td();
}


/*
 * Standardise element styling
 */
em {
	.f-fsty(italic);
}

/*
 * Reset header styling
 */
h1,
h2,
h3,
h4,
h5,
h6 {
	.m(0);
	.p(0);
	.f-fsi(100%);
	.f-fw();
}

/*
 * Clear default content from quotes
 * Set quotation marks
 */
q::before {
	content: open-quote;
}
q::after {
	content: close-quote;
}
:lang(en-GB) {
	quotes: '\201C' '\201D' '\2018' '\2019';
}

/*
 * Address style set to 'bolder' in Firefox 4+, Safari, and Chrome.
 */
strong {
	.f-fw(bold);
}

/*
 * Prevent 'sub' and 'sup' affecting 'line-height' in all browsers.
 */
sub,
sup {
	position: relative;
	.f-fsi(0.75em);
	.f-lh(0);
	vertical-align: baseline;
}
sup {
	.check-value(-0.5em, top);
}
sub {
	.check-value(-0.25em, bottom);
}


/* Embedded content
	 ========================================================================== */

/*
 * Remove border when inside 'a' element in IE 8/9/10.
 */
img {
	.b(none);
}

/*
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
	overflow: hidden;
}


/* Grouping content
	 ========================================================================== */

/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
	.m(0);
}

/* Forms
	 ========================================================================== */

/*
 * Known limitation: by default, Chrome and Safari on OS X allow very limited styling of 'select', unless a 'border' property is set.
 */

/*
 * 1. Correct color not being inherited. Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
	color: inherit; /* 1 */
	font: inherit; /* 2 */
	.m(0); /* 3 */
}

/*
 * Address 'overflow' set to 'hidden' in IE 8/9/10/11.
 */
button {
	overflow: visible;
}

/*
 * Address inconsistent 'text-transform' inheritance for 'button' and 'select'.
 * All other form control elements do not inherit 'text-transform' values.
 * Correct 'button' style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct 'select' style inheritance in Firefox.
 */
button,
select {
	.t-tt();
}

/*
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native 'audio' and 'video' controls.
 * 2. Correct inability to style clickable 'input' types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type 'input' and others.
 */
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; /* 2 */
	cursor: pointer; /* 3 */
}

/*
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
	cursor: default;
}

/*
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
	.b(none);
	.p(0);
}

/*
 * Address Firefox 4+ setting 'line-height' on 'input' using '!important' in the UA stylesheet.
 */
input {
	.f-lh(normal);
}

/*
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect padding, or width.
 *
 * 1. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
	.p(0); /* 1 */
}

/*
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain 'font-size' values of the 'input', it causes the cursor style of the decrement button to change from 'default' to 'text'.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	.h(auto);
}

/*
 * 1. Address 'appearance' set to 'searchfield' in Safari and Chrome.
 * 2. Address 'box-sizing' set to 'border-box' in Safari and Chrome
 *		(include '-moz' to future-proof).
 */
input[type="search"] {
	-webkit-appearance: textfield; /* 1 */
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box; /* 2 */
	box-sizing: content-box;
}

/*
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has padding (and 'textfield' appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/*
 * Define consistent border, margin, and padding.
 */
fieldset {
	.b(none);
	.m(0);
	.p(0);
}

/*
 * 1. Correct 'color' not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
	.b(none); /* 1 */
	.p(0); /* 2 */
}

/*
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
	overflow: auto;
}

/*
 * Don't inherit the 'font-weight' (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
	.f-fw(bold);
}


/* Tables
	 ========================================================================== */

/*
 * Remove most spacing between table cells.
 */
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption,
th {
	.f-fsty();
	.f-fw();
	.t-ta(left);
}
