.mcc-o-frm-input-grp {
	.fb();
	.fb-w(wrap);
	.m-mb(@rhythm / 2);

	&__lbl {
		.w(100%);

		@media (min-width: @bp-grid-xs) {
			.w(10);
		}

		&--auto {
			.w(auto);
			.m-mr(@gutter * 2);
		}
	}

	&__elm {
		.fb-fx(1);
		.w(5);
		.br(4px);
	}
}
