.mcc-o-btn {
	.h(@rhythm * 1.5);
	.b-bw(1px);
	.b-bc(@colour-primary);
	.br(4px);
	.p(1px @gutter / 2);

	.bg-co(@colour-primary);
	color: @colour-text-alternate;

	&--full {
		.w(100%);
	}

	&--lnk {
		.h(auto);
		.b(none);

		.bg-co(transparent);
		color: @colour-primary;
	}
}
