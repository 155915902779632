/*
 * Page
 * Page level styling, e.g. HTML and BODY elements
 */

html {
	.f-lh(@default-line-height);

	@media (min-width: @bp-background) {
		.bg-i('../images/background-min.jpg');
		.bg-a(fixed);
		.bg-p(center center);
		.bg-r(no-repeat);
		.bg-s(cover);
	}
}

body {
	.fb();
	.h-mih(100vh);

	.f-ff(~"Helvetica, Verdana, Arial, sans-serif");
	color: @colour-text;
}
