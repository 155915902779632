/*
 * Font Sizes
 * The various font sizes used within the project
 */

.mcc-u-font-size {
	&--perc {
		&-75 {
			.f-fsi(0.75);
		}

		&-150 {
			.f-fsi(1.5);
		}

		&-200 {
			.f-fsi(2);
		}

		&-300 {
			.f-fsi(3);
		}

		&-400 {
			.f-fsi(4);
		}
	}
}

.mcc-u-bold {
	font-weight: bold;
}
