/*
 * Share buttons
 */

.mcc-c-share-button {
	@diameter: 2.25rem;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	width: @diameter + 0.25;
	height: @diameter + 0.25;
	margin: 0.5rem;
	border-radius: 50%;

	background-color: @colour-secondary;
	border: 2px solid transparent;

	&:hover,
	&:active {
		border-color: darken(@colour-secondary, 15%);
	}

	&--facebook {
		background-color: #3b5998;

		&:hover,
		&:active {
			border-color: darken(#3b5998, 15%);
		}
	}

	&--twitter {
		background-color: #55acee;

		&:hover,
		&:active {
			border-color: darken(#55acee, 15%);
		}
	}

	&__ico {
		height: @diameter - 1;
		height: @diameter - 1;
	}
}
