/*
 * Links
 * Default styles for simple hyperlinks
 */

a {
	.td(none);
	color: @colour-primary;

	&:hover,
	&:active {
		.td(underline);
	}

	p & {
		.f-fw(bold);
	}

	tfoot & {
		color: @colour-text-alternate;
	}
}
