.mcc-c-hamburger {
	position:relative;
	display: block;

	.h(0.25);
	.w(1.5);

	.bg-co(@colour-text-alternate);

	&:after,
	&:before {
		position:absolute;
		.check-value(0, left);

		.h(0.25);
		.w(1.5);

		.bg-co(@colour-text-alternate);
		content:'';
	}

	&:after {
		.check-value(-0.5, bottom);
	}

	&:before {
		.check-value(-0.5, top);
	}
}
