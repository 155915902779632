.mcc-c-next-match {
	.b(1px solid @colour-primary);
	.p(@rhythm / 2, @gutter / 2);

	&--only {
		.m(0);
		.b(0);
		.p(0)
	}

	&__date {
	}

	&__oppo {
	}

	&__where {
	}
}

