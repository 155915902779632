/*
 * Columns
 * http://www.w3.org/TR/css3-multicol/
 */

// Value(s): <column-width> || <column-count>
.c(@columns) {
	columns: @columns;
}

// Value(s): auto | always | avoid | left | right | page | column | avoid-page | avoid-column
.c-cba(@break: auto) {
	-webkit-column-break-after: @break;
	page-break-after: @break; // Mozilla
	break-after: @break;
}

// Value(s): auto | always | avoid | left | right | page | column | avoid-page | avoid-column
.c-cbb(@break: auto) {
	-webkit-column-break-before: @break;
	page-break-before: @break; // Mozilla
	break-before: @break;
}

// Value(s): auto | avoid | avoid-page | avoid-column
.c-cbi(@break: auto) {
	-webkit-column-break-inside: @break;
	page-break-inside: @break; // Mozilla
	break-inside: @break;
}

// Value(s): <integer> | auto
.c-cc(@count: auto) {
	column-count: @count;
}

// Value(s): auto | balance
.c-cf(@fill: balance) {
	column-fill: @fill;
}

// Value(s): <length> | normal
.c-cg(@gap: normal) {
	.check-value(@gap, column-gap);
}

// Value(s): <column-rule-width> || <column-rule-style> || [ <column-rule-color> | transparent ]
.c-cr(@rule) {
	column-rule: @rule;
}

// Value(s): <color>
.c-crc(@colour) {
	column-rule-color: @color;
}

// Value(s): none | hidden | dotted | dashed | solid | double | groove | ridge | inset | outset
.c-crs(@style: none) {
	column-rule-style: @style;
}

// Value(s): thin | medium | thick | <length>
.c-crw(@width: medium) {
	.check-value(@width, column-rule-width);
}

// Value(s): none | all
.c-cs(@span: none) {
	column-span: @span;
}

// Value(s): <length> | auto
.c-cw(@width: auto) {
	.check-value(@width, column-width);
}
