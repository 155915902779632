.mcc-c-mnu {
	position: absolute;
	.check-value(0, top);
	.check-value(-18, right);
	transition: right 0.5s ease;

	.w(100%);
	.w-maw(18);
	.b-bs(solid);
	.b-bc(@colour-primary);
	.b-bw(0 0 @gutter / 2 @gutter / 2);

	overflow: hidden;

	.bg-co(@colour-bg);

	@media (min-width: @bp-background) {
		.h(100%);
		.b-btw(@gutter / 2);
		.br(0, @gutter, @gutter, 0);

		overflow-y: auto;
	}

	&.is-open {
		.check-value(0, right);
	}

	&__itm {
		.bg-co(@colour-bg);

	}

	&__ttl {
		display: block;
		.p-pv(@rhythm / 4);
		.p-ph(@gutter / 2, @gutter * 2);
	}

	&__lnk {
		display: block;
		.p-pv(@rhythm / 4);
		.p-ph(@gutter / 2, @gutter);

		&:hover,
		&:active {
			.td();
		}

		&:hover,
		&:active,
		&.is-selected {
			.bg-co(@colour-primary);
			color: @colour-text-alternate;
		}

		&--sub {
			.p-pl(@gutter * 3);

			&:hover,
			&:active,
			&.is-selected {
				.bg-co(@colour-secondary);
				color: @colour-primary;
			}
		}

		&:not(.mcc-c-mnu__lnk--sub) {
			&::before {
				content: "\00BB";
				display: inline-block;
				.w(1);
			}
		}
	}
}
