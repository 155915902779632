.mcc-c-news-calendar {
	&__year {}

	&__ttl {
		.f-fw(bold);
	}

	&__month {
		@media (min-width: @bp-grid-lg) {
			.c-cc(2);
			.c-cg(1);
		}
	}

	&__lnk {
		display: block;

		@media (min-width: @bp-grid-md) {
			.m-mh(@gutter * 3/4);

			&:hover,
			&:active {
				&::before,
				&::after {
					display: inline-block;
					.w(@gutter * 3/4);
				}

				&::before {
					content: "\00BB";
					.m-ml(-@gutter * 3/4);
					.t-ta(left);
				}

				&::after {
					content: "\00AB";
					.m-mr(-@gutter * 3/4);
					.t-ta(right);
				}
			}
		}
	}
}
